import React from "react"
import { Link } from "gatsby"
import { Row, Col, Container, Breadcrumb } from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

const SitemapPageEn = () => (
  <Layout backButton={true} enSite pageInfo={{ pageName: "sitemap-en" }}>
    <Seo title="Site map" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/en">HOME</Link>
            </li>
            <Breadcrumb.Item active>SITE MAP</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row className="mb-5 product-container">
        <Col sm={12} className="">
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Site map
          </h1>
          <h2>English</h2>
        </Col>

        <Col sm={12} md={6}>
          <h4>Products</h4>
          <ul>
            <li>
              <Link to="/lutein-complex">Lutein Complex Plus</Link>
            </li>
            <li>
              <Link to="/hepatofelin">Hepatofelin</Link>
            </li>
            <li>
              <Link to="/stavien">Stavien</Link>
            </li>
            <li>
              <Link to="/prostatizal-plus">Prostatizal Plus</Link>
            </li>
            <li>
              <Link to="/psoriazal-plus">Psoriazal Plus</Link>
            </li>
            <li>
              <Link to="/laksiprokt">Laksiprokt</Link>
            </li>
            <li>
              <Link to="/liporedukt">Liporedukt</Link>
            </li>
            <li>
              <Link to="/menopauzal">Menopauzal</Link>
            </li>
            <li>
              <Link to="/miomal">Miomal</Link>
            </li>
            <li>
              <Link to="/nevroherb">Nevroherb</Link>
            </li>
            <li>
              <Link to="/pielonefral">Pielonefral</Link>
            </li>
            <li>
              <Link to="/prostatizal">Prostatizal</Link>
            </li>
            <li>
              <Link to="/psoriazal">Psoriazal</Link>
            </li>
            <li>
              <Link to="/veniprotekt">Veniprotekt</Link>
            </li>
          </ul>
        </Col>
        <Col sm={12} md={6}>
          <h4>Information</h4>
          <ul>
            <li>
              <Link to="/contact">Contacts</Link>
            </li>
            <li>
              <Link to="/about">About Vita Herb</Link>
            </li>
            <li>
              <Link to="/privacy">Privacy</Link>
            </li>
            <li>
              <Link to="/technology">Technology</Link>
            </li>
          </ul>
        </Col>
        <Col sm={12} className="">
          <h2>Bulgarian</h2>
        </Col>

        <Col sm={12} md={6}>
          <h4>Продукти</h4>
          <ul>
            <li>
              <Link to="/lutein-complex">Lutein Complex Plus</Link>
            </li>
            <li>
              <Link to="/hepatofelin">Hepatofelin</Link>
            </li>
            <li>
              <Link to="/stavien">Stavien</Link>
            </li>
            <li>
              <Link to="/prostatizal-plus">Prostatizal Plus</Link>
            </li>
            <li>
              <Link to="/psoriazal-plus">Psoriazal Plus</Link>
            </li>
            <li>
              <Link to="/laksiprokt">Laksiprokt</Link>
            </li>
            <li>
              <Link to="/liporedukt">Liporedukt</Link>
            </li>
            <li>
              <Link to="/menopauzal">Menopauzal</Link>
            </li>
            <li>
              <Link to="/miomal">Miomal</Link>
            </li>
            <li>
              <Link to="/nevroherb">Nevroherb</Link>
            </li>
            <li>
              <Link to="/pielonefral">Pielonefral</Link>
            </li>
            <li>
              <Link to="/prostatizal">Prostatizal</Link>
            </li>
            <li>
              <Link to="/psoriazal">Psoriazal</Link>
            </li>
            <li>
              <Link to="/veniprotekt">Veniprotekt</Link>
            </li>
          </ul>
        </Col>
        <Col sm={12} md={6}>
          <h4>Информация</h4>
          <ul>
            <li>
              <Link to="/contact">Контакти</Link>
            </li>
            <li>
              <Link to="/about">За Вита Херб</Link>
            </li>
            <li>
              <Link to="/privacy">Защита на личните данни</Link>
            </li>
            <li>
              <Link to="/articles">Статии</Link>
            </li>
            <li>
              <Link to="/technology">Технология</Link>
            </li>
          </ul>
        </Col>
        <Col sm={12} md={6}>
          <h4>Статии</h4>
          <ul>
            <li>
              <Link to="/articles/cherniat-drob-ne-boli">
                Черният дроб не боли - той се срива изведнъж
              </Link>
            </li>
            <li>
              <Link to="/articles/purva-pomosht">Първа помощ за очите</Link>
            </li>
            <li>
              <Link to="/articles/virusite-ni-atakuvat-prez-ochite">
                Вирусите ни атакуват и през очите
              </Link>
            </li>
            <li>
              <Link to="/articles/kakvo-e-hialuronova-kiselina">
                Какво е хиалуронова киселина и как ни помага тя?
              </Link>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default SitemapPageEn
